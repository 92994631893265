const shortUUID = () => {
  // I generate the UID from two parts here
  // to ensure the random number provide enough bits.
  // eslint-disable-next-line no-bitwise
  let firstPart = (Math.random() * 46656) | 0;
  // eslint-disable-next-line no-bitwise
  let secondPart = (Math.random() * 46656) | 0;
  firstPart = (`000${firstPart.toString(36)}`).slice(-3);
  secondPart = (`000${secondPart.toString(36)}`).slice(-3);
  return firstPart + secondPart;
};

const unixTimeStamp = () => `${Math.floor(Date.now() / 1000)}`;


const sanitizePassword = (password) => {
  const supportedSpecialChars = ` !"#$%&'()*+,-./:;<=>?@[\\]^_\`{|}~`;
  const regex = new RegExp(`[^a-zA-Z0-9${supportedSpecialChars.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')}]`, 'g');
  return password.replace(regex, '');
}

export default {
  shortUUID,
  unixTimeStamp,
  sanitizePassword
};
